import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification, SingleNotification, User } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/notification/`;
  private basePath = `https://backalicorp.cocoalatam.com/api/notification/`;

  constructor(private readonly http: HttpClient) {}

  sendAllUsers(notification: Notification): Observable<Notification[]> {
    return this.http.post<Notification[]>(
      `${this.basePath}sendAllUser`,
      notification
    );
  }

  sendToUser(notification: SingleNotification): Observable<SingleNotification[]> {
    return this.http.post<SingleNotification[]>(
      `${this.basePath}sendbyUserId`,
      notification
    );
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.basePath}getUsers`,
    );
  }
}
