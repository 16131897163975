import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer, Invoice, Product, Rol } from './api.types';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/customer/`;
  // private basePath2 = `https://alicorp-back.herokuapp.com/api/billing/`;

  private basePath = `${environment.baseUrl}/api/customer/`;
  private basePath2 = `${environment.baseUrl}/api/billing/`;

  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.basePath}`);
  }

  getAllInvoices(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${this.basePath2}`);
  }

  getBy(id: number): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.basePath}${id}`);
  }

  create(file: File, customer: Customer): Observable<Customer[]> {
    const data = new FormData();
    data.append('archivo', file);
    data.append('alicorp_cod', customer.alicorp_cod);
    data.append('ci_number', customer.ci_number);
    data.append('name', customer.name);
    data.append('email', customer.email);
    data.append('phone', customer.phone);
    data.append('departamento_id', customer.departamento_id);
    data.append('mercado_id', customer.mercado_id);
    data.append('customer_type_id', customer.customer_type_id);
    data.append('red_seller', customer.red_seller);
    data.append('green_seller', customer.green_seller);

    return this.http.post<Customer[]>(`${this.basePath}create`, data);
  }

  edit(file: File, customer: Customer): Observable<Customer> {
    console.log(file, customer)
    const data = new FormData();
    data.append('archivo', file);
    data.append('alicorp_cod', customer.alicorp_cod);
    data.append('ci_number', customer.ci_number);
    data.append('name', customer.name);
    data.append('email', customer.email);
    data.append('phone', customer.phone);
    data.append('departamento_id', customer.departamento_id);
    data.append('mercado_id', customer.mercado_id);
    data.append('customer_type_id', customer.customer_type_id);
    data.append('red_seller', customer.red_seller);
    data.append('green_seller', customer.green_seller);

    return this.http.put<Customer>(
      `${this.basePath}update/${customer.id}`,
      data
    );
  }

  delete(id: number): Observable<Customer> {
    return this.http.delete<Customer>(`${this.basePath}delete/${id}`);
  }

  addCustomers(file: File, customerState: string): Observable<Customer[]> {
    const adjunt = new FormData();
    adjunt.append('archivo', file);
    return this.http.post<Customer[]>(`${this.basePath}upload/`, adjunt);
  }

  addInvoices(file: File, customerState: string): Observable<Invoice[]> {
    const adjunt = new FormData();
    adjunt.append('archivo', file);
    return this.http.post<Invoice[]>(`${this.basePath2}upload_exel/`, adjunt);
  }
}
