import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Departament, Notification, User } from './api.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MercadoService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/mercado`;
  private basePath = `${environment.baseUrl}/api/mercado`;

  constructor(private readonly http: HttpClient) {}

  getAll(departamentId): Observable<Departament[]> {
    return this.http.get<Departament[]>(`${this.basePath}/${departamentId}`);
  }
}
