import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Item, Product, Rol } from "./api.types";

@Injectable({
  providedIn: "root",
})
export class ImagesService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/visibility/user_visibilidad_imagnes/`
  private basePath = `https://backalicorp.cocoalatam.com/api/visibility/user_visibilidad_imagnes/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(id): Observable<Item[]> {
    return this.http.get<Item[]>(`${this.basePath}web/${id}`);
  }

  getBy(id: number): Observable<Item[]> {
    return this.http.get<Item[]>(`${this.basePath}${id}`);
  }
  
  create(file: File, image): Observable<any[]> {
    const data = new FormData();
    data.append('archivo', file);
    data.append('descripcion', image.descripcion);
    data.append('idcustomer', image.idcustomer);

    console.log(data)
    return this.http.post<any[]>(`${this.basePath}create`, data);
  }

  edit(item: Item): Observable<Item> {
    return this.http.put<Item>(`${this.basePath}update/${item.id}`, item);
  }

  delete(id: number): Observable<Product> {
    return this.http.post<Product>(`${this.basePath}delete/${id}`, {});
  }
}
