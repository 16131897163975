import { NgModule } from '@angular/core';

 //modulo ReactiveForm
 import { ReactiveFormsModule }  from '@angular/forms';

//modulos angular material
 import { MatSliderModule }          from '@angular/material/slider';
 import { MatFormFieldModule }       from '@angular/material/form-field';
 import { MatInputModule }           from '@angular/material/input';
 import { MatSnackBarModule }        from '@angular/material/snack-bar';
 import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
 import { MatButtonModule }          from '@angular/material/button';
 import { MatToolbarModule }         from '@angular/material/toolbar';
 import { MatIconModule }            from '@angular/material/icon';
 import { MatMenuModule }            from '@angular/material/menu';
 import { MatTableModule }           from '@angular/material/table';
 import { MatGridListModule }        from '@angular/material/grid-list';
 import { MatTooltipModule }         from '@angular/material/tooltip';
 import { MatPaginatorModule }       from '@angular/material/paginator';
 import { MatSortModule }            from '@angular/material/sort';
 import { MatDialogModule }          from '@angular/material/dialog';
 import { MatCardModule }            from '@angular/material/card';
 import { MatDatepickerModule }      from '@angular/material/datepicker';
 import { MatNativeDateModule }      from '@angular/material/core';
 import { MatSelectModule }          from '@angular/material/select';
 import { MatDividerModule }         from '@angular/material/divider';
 import { MatCheckboxModule }        from '@angular/material/checkbox';
 import {MatProgressBarModule}       from '@angular/material/progress-bar';
 import { MatListModule }            from '@angular/material/list';
 import {MatChipsModule}             from '@angular/material/chips';
 import {MatBadgeModule} from '@angular/material/badge';

 import {MatSidenavModule}           from '@angular/material/sidenav';
 import {MatAutocompleteModule} from '@angular/material/autocomplete';


 import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  exports: [
     MatSliderModule,
     MatFormFieldModule,
     MatInputModule,
     MatSnackBarModule,
     MatProgressSpinnerModule,
     MatButtonModule,
     MatToolbarModule,
     MatIconModule,
     MatMenuModule,
     MatTableModule,
     MatGridListModule,
     MatTooltipModule,
     MatPaginatorModule,
     MatSortModule,
     MatDialogModule,
     MatCardModule,
     ReactiveFormsModule,
     MatDatepickerModule,
     MatNativeDateModule,
     MatSelectModule,
     MatDividerModule,
     MatCheckboxModule,
     MatProgressBarModule,
     MatListModule,
     MatChipsModule,
     PdfViewerModule,
     MatSidenavModule,
     MatBadgeModule,
     MatAutocompleteModule
  ]
})
export class SharedMaterialModule { }
