import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notice } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/notice/`;
  private basePath = `https://backalicorp.cocoalatam.com/api/notice/`;

  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<Notice[]> {
    return this.http.get<Notice[]>(`${this.basePath}`);
  }

  getBy(id: number): Observable<Notice[]> {
    return this.http.get<Notice[]>(`${this.basePath}${id}`);
  }

  create(fileImage: File, filePdf: File, notice: Notice): Observable<Notice[]> {
    const data = new FormData();
    console.log(filePdf);
    data.append('archivo', fileImage);
    data.append('pdf', filePdf);
    data.append('nombrecomunicado', notice.nombrecomunicado);

    return this.http.post<Notice[]>(`${this.basePath}create`, data);
  }

  createV2(fileImage: File, filePdf: File, notice: any): Observable<Notice[]> {
    const data = new FormData();
    console.log(notice);
    data.append('portada_imagen', fileImage);
    if(notice.tipo === 'youtube'){
      data.append('video_pdf', '');
    }else{
      data.append('video_pdf', filePdf);
    }
    data.append('nombrecomunicado', notice.nombrecomunicado);
    data.append('tipo', notice.tipo);
    data.append('urlyoutube', notice.urlyoutube);

    return this.http.post<Notice[]>(`${this.basePath}createv2`, data);
  }

  editV2(fileImage: File, filePdf: File, notice: any): Observable<Notice> {
    const data = new FormData();
    data.append('portada_imagen', fileImage);
    if(notice.tipo === 'youtube'){
      data.append('video_pdf', '');
    }else{
      data.append('video_pdf', filePdf);
    }
    data.append('nombrecomunicado', notice.nombrecomunicado);
    data.append('idcomunicado', notice.id);
    data.append('urlyoutube', notice.urlyoutube);
    data.append('tipo', notice.tipo);
    return this.http.put<any>(`${this.basePath}actualizar/`, data);
  }

  edit(fileImage: File, filePdf: File, notice: Notice): Observable<Notice> {
    const data = new FormData();
    if (filePdf) {
      console.log('exite pdf');
      data.append('pdf', filePdf);
    }
    data.append('archivo', fileImage);
    data.append('nombrecomunicado', notice.nombrecomunicado);

    return this.http.put<Notice>(`${this.basePath}update/${notice.id}`, data);
  }

  delete(id: number): Observable<Notice> {
    return this.http.delete<Notice>(`${this.basePath}delete/${id}`);
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }
}
