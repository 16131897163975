import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Campaign, Customer, Product, Rol } from "./api.types";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CampaignService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/campaign/`
  private basePath = `${environment.baseUrl}/api/campaign/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(): Observable<Campaign[]> {
    return this.http.get<Campaign[]>(`${this.basePath}`);
  }

  getBy(id: number): Observable<Campaign[]> {
    return this.http.get<Campaign[]>(`${this.basePath}${id}`);
  }
  
  create(campaign: Campaign): Observable<Campaign[]> {
    return this.http.post<Campaign[]>(`${this.basePath}create`, campaign);
  }

  edit(campaign: Campaign): Observable<Campaign> {
    return this.http.put<Campaign>(`${this.basePath}update/${campaign.id}`, campaign);
  }

  delete(id: number): Observable<Campaign> {
    return this.http.delete<Campaign>(`${this.basePath}delete/${id}`);
  }
}
