import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequest, TokenResponse } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/user/`;
  private basePath = `https://backalicorp.cocoalatam.com/api/user/`;

  constructor(private readonly http: HttpClient) {}

  requestToken(loginRequest: LoginRequest): Observable<any> {
    return this.http.post<TokenResponse>(
      this.basePath + 'loginweb/',
      loginRequest
    );
  }
}
