import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Departament, Notification, User } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class DepartamentService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/deparrtamento/`;
  private basePath = `https://backalicorp.cocoalatam.com/api/deparrtamento/`;

  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<Departament[]> {
    return this.http.get<Departament[]>(`${this.basePath}`);
  }
}
