import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer, Invoice, Product, Rol, sellerJSV, SellerResponse } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/seller/`;
  private basePath = `https://backalicorp.cocoalatam.com/api/seller/`;

  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.basePath}`);
  }

  getAllSellerReport(): Observable<SellerResponse[]> {
    return this.http.get<SellerResponse[]>(`${this.basePath}sales_manager`);
  }

  addSellers(file: File, customerState: string): Observable<SellerResponse[]> {
    const adjunt = new FormData();
    adjunt.append('archivo', file);
    return this.http.post<SellerResponse[]>(`${this.basePath}upload/`, adjunt);
  }

  getSalesManager(): Observable<sellerJSV[]> {
    return this.http.get<sellerJSV[]>(`${this.basePath}only_sales_managerJSV`);
  }

  getSupervisors(): Observable<SellerResponse[]> {
    return this.http.get<SellerResponse[]>(`${this.basePath}only_supervisorJSV`);
  }

  getSellers(): Observable<SellerResponse[]> {
    return this.http.get<SellerResponse[]>(`${this.basePath}only_sellerJSV`);
  }

  sendAccessJSV(data): Observable<SellerResponse[]> {
    return this.http.post<SellerResponse[]>(`${this.basePath}sendAccessJSV/`, data);
  }
}
