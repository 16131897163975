import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Product, ProductType, Rol } from "./api.types";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductTypeService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/product_type/`
  private basePath = `${environment.baseUrl}/api/product_type/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(`${this.basePath}`);
  }

  getBy(id: number): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(`${this.basePath}${id}`);
  }
  
  create(productType: ProductType): Observable<ProductType[]> {
    return this.http.post<ProductType[]>(`${this.basePath}create`, productType);
  }

  edit(productType: ProductType): Observable<ProductType> {
    return this.http.put<ProductType>(`${this.basePath}update/${productType.id}`, productType);
  }

  delete(id: number): Observable<ProductType> {
    return this.http.delete<ProductType>(`${this.basePath}delete/${id}`);
  }
}
