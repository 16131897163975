import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AccessDevice,
  AccessReport,
  AccessReportResponse,
  User,
} from './api.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/`;
  private basePath = `${environment.baseUrl}/api/`;

  constructor(private readonly http: HttpClient) {}

  getAccess(data: AccessReport): Observable<AccessReportResponse[]> {
    return this.http.post<AccessReportResponse[]>(
      `${this.basePath}access`,
      data
    );
  }

  getAccessByDevice(): Observable<AccessDevice[]> {
    return this.http.get<AccessDevice[]>(`${this.basePath}access/deviceCount`);
  }

  getAccessByDays(date: string): Observable<any> {
    return this.http.post<any[]>(`${this.basePath}access/getDias`,date);
  }

  getAccessByMontly(date: string): Observable<any> {
    return this.http.post<any[]>(`${this.basePath}access/getMes`,date);
  }
}
