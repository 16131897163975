import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Employed, MixFamily, Visibility } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/transactions/`;
  // private baseSellerPath = `https://alicorp-back.herokuapp.com/api/seller/`;
  // private baseVisibilityPath = `https://alicorp-back.herokuapp.com/api/visibility/`;

  private basePath = `https://backalicorp.cocoalatam.com/api/transactions/`;
  private baseSellerPath = `https://backalicorp.cocoalatam.com/api/seller/`;
  private baseVisibilityPath = `https://backalicorp.cocoalatam.com/api/visibility/`;

  constructor(private readonly http: HttpClient) {}

  getAll(): Observable<MixFamily[]> {
    return this.http.get<MixFamily[]>(`${this.basePath}`);
  }

  addMixFamily(file: File, tipocliente: string): Observable<MixFamily[]> {
    const adjunt = new FormData();
    adjunt.append('archivo', file);
    adjunt.append('tipo_cliente', tipocliente);

    return this.http.post<MixFamily[]>(
      `${this.basePath}carga_familia/`,
      adjunt
    );
  }

  getAllVisibilityOld(): Observable<Visibility[]> {
    return this.http.get<Visibility[]>(`${this.basePath}`);
  }

  getAllVisibility(id: number): Observable<Visibility[]> {
    const adjunt = new FormData();
    adjunt.append('campana', id.toString());

    return this.http.post<Visibility[]>(`${this.baseVisibilityPath}users_visibilidad`, adjunt);
  }

  addVisibility(file: File): Observable<Visibility[]> {
    const adjunt = new FormData();
    adjunt.append('archivo', file);

    return this.http.post<MixFamily[]>(
      `${this.baseVisibilityPath}excel_visibilidad/`,
      adjunt
    );
  }

  getGeneralAdvance(id: number): Observable<Visibility[]> {
    const adjunt = new FormData();
    adjunt.append('sales_manager_id', '1');
    adjunt.append('campaign_id', id.toString());

    return this.http.post<Visibility[]>(
      `${this.basePath}get_avance_general_campana/`,
      adjunt
    );
  }

  getGeneralAdvanceFilter(
    managerId: number,
    supervisorId: number,
    sellerId: number
  ): Observable<Visibility[]> {
    const adjunt = new FormData();
    adjunt.append('sales_manager_id', managerId + '');
    adjunt.append('supervisor_id', supervisorId + '');
    adjunt.append('seller_id', sellerId + '');

    return this.http.post<Visibility[]>(
      `${this.basePath}get_avance_general/`,
      adjunt
    );
  }

  getFamilyAdvance(customer_id:number, customer_type_id: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.basePath}avance_familia/web/${customer_id}?customer_type_id=${customer_type_id}`
    );
  }

  getManagers(): Observable<Employed[]> {
    return this.http.get<Employed[]>(
      `${this.baseSellerPath}only_sales_manager/`
    );
  }

  getSupervisors(managerId: number): Observable<Employed[]> {
    return this.http.get<Employed[]>(
      `${this.baseSellerPath}supervisor_manager/${managerId}`
    );
  }

  getSellers(supervisorId: number): Observable<Employed[]> {
    return this.http.get<Employed[]>(
      `${this.baseSellerPath}seller_supervisor/${supervisorId}`
    );
  }
}
