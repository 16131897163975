import { LoginResponse } from 'src/app/api-client/api.types';

export const SESSION_NAME = 'l0y4ltyk3y';

export function getLoginResponseData(): LoginResponse {
  const keyFromSessionStorage = sessionStorage.getItem(SESSION_NAME);
  if (!keyFromSessionStorage) {
    return <LoginResponse>{
      ok: false,
      token: 'none',
      username: 'Invitado',
    };
  }

  return <LoginResponse>JSON.parse(keyFromSessionStorage);
}

export function removeSessionData() {
  sessionStorage.removeItem(SESSION_NAME);
}

export function isAdmin() {
  return (
    JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.role ===
    'USER_ADMIN'
  );
}

export function isManager() {
  return (
    JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.role ===
    'USER_MANAGERS'
  );
}

export function isSupervisor() {
  return (
    JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.role ===
    'USER_SUPERVISORS'
  );
}

export function isSeller() {
  return (
    JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.role ===
    'USER_SELLER'
  );
}

export function roleId(): number {
  return JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.id;
}

export function getRoleName() {
  var role = JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.role;
  if (role == 'USER_ADMIN') return 'Administrador';
  if (role == 'USER_MANAGERS') return 'Jefe';
  if (role == 'USER_SUPERVISORS') return 'Supervisor';
  if (role == 'USER_SELLER') return 'Vendedor';
  return '';
}

export function getUsername() {
  return JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.username;
}

export function getHigherEmployed(): number {
  return JSON.parse(sessionStorage.getItem(SESSION_NAME) || '{}')?.higher;
}
