<div class="sidebar-wrapper">
  <div class="logo">
    <div class="text-center w-100">
      <img src="assets/logo.jpg" class="" style="height: 150px" />
    </div>

    <small class="text-danger">Bienvenido, {{ getUser() }}</small>
  </div>
  <ul class="nav">
    <li class="" routerLinkActive="active">
      <a class="" [routerLink]="['/dashboard']" data-bs-dismiss="offcanvas">
        <i class="nc-icon nc-bank"></i>
        <div class="font-weight-bold">
          <p class="text-danger">Inicio</p>
        </div>
      </a>
    </li>
    <li
      *ngFor="let menuItem of menuItems"
      routerLinkActive="active"
      class="{{ menuItem.class }}"
    >
      <a *ngIf="menuItem.visibility">
        <i class="nc-icon {{ menuItem.icon }}"></i>
        <div class="font-weight-bold">
          <p class="text-danger">{{ menuItem.title }}</p>
        </div>
        <hr class="mt-1 mb-1" />
      </a>
      <div
        *ngIf="menuItem.childs && menuItem.visibility"
        routerLinkActive="active"
      >
        <div *ngFor="let item of menuItem.childs">
          <div *ngIf="item.visibility">
            <a [routerLink]="[item.path]" *ngIf="item.visibility">
              <div
                style="margin-left: 25%; margin-bottom: 5px"
                class="font-smaller"
              >
                {{ item.title }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </li>
    <li class="nav-item active">
      <a
        class="dropdown-item ml-5"
        [routerLink]="['/login']"
        data-bs-dismiss="offcanvas"
        (click)="logout()"
      >
        <p>Cerrar Sesión</p>
      </a>
    </li>
  </ul>
</div>
