import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { User } from "./api.types";

@Injectable({
  providedIn: "root",
})
export class UserService {
  //private basePath = `https://alicorp-back.herokuapp.com/api/user/`
  private basePath = `https://backalicorp.cocoalatam.com/api/user/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(`${this.basePath}`);
  }

  getBy(id: number): Observable<User[]> {
    return this.http.get<User[]>(`${this.basePath}${id}`);
  }

  create(usuario: User): Observable<User[]> {
    return this.http.post<User[]>(`${this.basePath}create_user`, usuario);
  }

  edit(usuario: User): Observable<User> {
    return this.http.put<User>(`${this.basePath}update/${usuario.id}`, usuario);
  }

  delete(id: number): Observable<User> {
    return this.http.delete<User>(`${this.basePath}delete/${id}`);
  }
}
