import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Item, Product, Rol } from "./api.types";

@Injectable({
  providedIn: "root",
})
export class ItemService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/items/`
  private basePath = `https://backalicorp.cocoalatam.com/api/items/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(): Observable<Item[]> {
    return this.http.get<Item[]>(`${this.basePath}`);
  }

  getBy(id: number): Observable<Item[]> {
    return this.http.get<Item[]>(`${this.basePath}${id}`);
  }
  
  create(item: Item): Observable<Item[]> {
    return this.http.post<Item[]>(`${this.basePath}create`, item);
  }

  edit(item: Item): Observable<Item> {
    return this.http.put<Item>(`${this.basePath}update/${item.id}`, item);
  }

  delete(id: number): Observable<Product> {
    return this.http.delete<Product>(`${this.basePath}delete/${id}`);
  }
}
