import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Customer, Product, Rol } from "./api.types";

@Injectable({
  providedIn: "root",
})
export class CustomerTypeService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/customer_type/`
  private basePath = `https://backalicorp.cocoalatam.com/api/customer_type/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.basePath}`);
  }
}
