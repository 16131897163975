import { getLoginResponseData } from '../security/security.utils';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    const loginData = getLoginResponseData();
    if (!loginData.ok) {
      this.router.navigate(['/login']);
      return false;
    }

    return loginData.ok;
  }
}
