import { Component, OnInit } from '@angular/core';
import {
  getRoleName,
  getUsername,
  isAdmin,
  removeSessionData,
} from 'src/app/shared/security/security.utils';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  childs: RouteInfo[];
  visibility: boolean;
}

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];

  ROUTES: RouteInfo[] = [
    {
      path: '/seguridad',
      title: 'Seguridad',
      icon: 'nc-single-02',
      class: '',
      visibility: isAdmin(),
      childs: [
        {
          path: '/seguridad/usuarios',
          title: 'Usuarios',
          icon: 'nc-bag-16',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/seguridad/roles',
          title: 'Roles y permisos',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
      ],
    },
    {
      path: '/gestion',
      title: 'Gestión',
      icon: 'nc-bag-16',
      class: '',
      visibility: isAdmin(),
      childs: [
        {
          path: '/gestion/acceso-jefes',
          title: 'Acceso Jefes',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/acceso-supervisores',
          title: 'Accesos Supervisores',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/acceso-vendedores',
          title: 'Acceso Vendedores',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/clientes',
          title: 'Clientes',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/objetivos',
          title: 'Productos',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/items',
          title: 'Items',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/comunicados',
          title: 'Comunicados',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/campaña',
          title: 'Campaña',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/gestion/piramide',
          title: 'Piramide',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
      ],
    },
    {
      path: '/objetivos',
      title: 'Objetivos',
      icon: 'nc-bag-16',
      class: '',
      visibility: isAdmin(),
      childs: [
        {
          path: '/objetivos/facturacion',
          title: 'Facturación',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/objetivos/mix_familia',
          title: 'Mix Familia',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/objetivos/visibilidad',
          title: 'Visibilidad',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
      ],
    },
    {
      path: '/reportes',
      title: 'Reportes',
      icon: 'nc-bag-16',
      class: '',
      visibility: true,
      childs: [
        {
          path: '/reportes/accesos',
          title: 'Accesos a la aplicación',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/reportes/avance-general',
          title: 'Avance General',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/reportes/avance-general-filtro',
          title: 'Avance General por Filtro',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: true,
        },
      ],
    },
    {
      path: '/notificaciones',
      title: 'Notificaciones',
      icon: 'nc-bag-16',
      class: '',
      visibility: isAdmin(),
      childs: [
        {
          path: '/notificaciones/nuevo',
          title: 'Nueva notificación masiva',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
        {
          path: '/notificaciones/notificacion-individual',
          title: 'Nueva notificación individual',
          icon: 'nc-bank',
          class: '',
          childs: [],
          visibility: isAdmin(),
        },
      ],
    },
  ];

  ngOnInit() {
    this.menuItems = this.ROUTES.filter((menuItem) => menuItem);
    console.log('onInit');
  }

  logout() {
    removeSessionData();
  }

  getUser() {
    return getUsername() + ' - ' + getRoleName();
  }
}
