import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Product, Rol } from "./api.types";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  // private basePath = `https://alicorp-back.herokuapp.com/api/products/`
  private basePath = `https://backalicorp.cocoalatam.com/api/products/`

  constructor(
    private readonly http: HttpClient,
  ) {}

  getAll(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.basePath}`);
  }

  getBy(id: number): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.basePath}${id}`);
  }
  
  create(product: Product): Observable<Product[]> {
    return this.http.post<Product[]>(`${this.basePath}create`, product);
  }

  edit(product: Product): Observable<Product> {
    return this.http.put<Product>(`${this.basePath}update/${product.id}`, product);
  }

  delete(id: number): Observable<Product> {
    return this.http.delete<Product>(`${this.basePath}delete/${id}`);
  }
}
