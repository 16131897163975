import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RolService } from './rol.service';
import { UserService } from './user.service';
import { ProductService } from './product.service';
import { CustomerService } from './customer.service';
import { ProductTypeService } from './product-type.service';
import { ItemService } from './item.service';
import { CampaignService } from './campaign.service';
import { NoticeService } from './notice.service';
import { CustomerTypeService } from './customer-type.service';
import { ReportService } from './report.service';
import { SellerService } from './seller.service';
import { TransactionsService } from './transactions.service';
import { LoginService } from './login.service';
import { NotificationService } from './notification.service';
import { DepartamentService } from './departament.service';
import { MercadoService } from './mercado.service';
import { ImagesService } from './images.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    UserService,
    RolService,
    ProductService,
    CustomerService,
    ProductTypeService,
    ItemService,
    CampaignService,
    NoticeService,
    CustomerTypeService,
    ReportService,
    SellerService,
    TransactionsService,
    LoginService,
    NotificationService,
    DepartamentService,
    MercadoService,
    ImagesService
  ],
})
export class ApiClientModule {}
